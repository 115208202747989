<template>
    <section>
        <i-sticky>
            <i-header class="groupheader" title="参团基本信息" theme="light" :showBack="true"
                      @back-event="eventDetailGoBack">
            </i-header>
        </i-sticky>
        <div class="form_box">
            <i-form v-if="template && template[0]" ref="form" @submit="onSubmit">
                <iFormItem v-for="item in template[0]" :key="item.type + item.controlName" :formData="formData" :formTemplate="template" :model-val="formData[item.controlName]" :ref="item.controlName" :form-item="item" @callBack="formCallBack"></iFormItem>

                <i-button v-if="!$route.query.readOnly" :loading="loading" :disabled="loading" loading-text="参团" block round type="primary"
                          size="normal"> 参团
                </i-button></i-form>
<!--            <component v-if="listTemplateConfig && listTemplateConfig.template" :is="$evalTemplate('Vnode', listTemplateConfig.template, submitTopFilter)"></component>-->
        </div>
    </section>
</template>

<script>
import Button from "vant/lib/button";
import "vant/lib/button/style";
// import { iScroll } from "@/icomponents";
import { Search } from "vant";
import { Dialog } from "vant";
import "vant/lib/search/style";
import iFormItem from "@/components/iFormItem/iFormItem";
import {getCookie} from "tiny-cookie";

export default {
    name: "message",
    components: {
        // iScroll,
        iFormItem,
        [Button.name]: Button,
        [Dialog.Component.name]: Dialog.Component,
        [Search.name]: Search,
    },

    methods: {
        eventDetailGoBack() {
            ccApp.$router.back();
        },

        createEvent() {
            console.log("Creating event");
        },

        resetTopFilter() {
            this.getList();
            this.hideFilter();
        },
        submitTopFilter(doSomething) {
            let iDialog = Dialog;
            let _this = this;
            doSomething && eval(doSomething);
        },
        formCallBack(value, index, formItem) {
            console.log("====点击表单", value, formItem);
            let _this = this;
            if (formItem.callBackExpression) {
                let context = { value };
                eval(formItem.callBackExpression);
            }
            // 表单数据赋值
            if (value) {
                this.formData[formItem.controlName] = value.val || value.value || value[formItem.valName] || value;
            } else {
                this.formData[formItem.controlName] = "";
            }
            // 执行表单配置功能
            if (formItem.type == "iSelectRest") {
                // 根据表单项的值控制其他表单项的显隐藏
                // eslint-disable-next-line no-unused-vars
                let context = this.formData;
                console.log("====formitem的callback", formItem.controlName, value);
                this.template[0] = this.stashTemplate[0].filter((item) => {
                    if (item.visibleRuleExpr) {
                        // 命中规则才显示
                        try {
                            return item.visibleRuleExpr.every((subItem) => eval(subItem));
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        return true;
                    }
                });
            } else if (formItem.type == "automaticFill") {
                // 赋值其他表单项
                console.log("====需要自动填充内容", value, formItem.fillFormItem);
                this.formData[formItem.controlName] = value[formItem.controlName];
                formItem.fillFormItem.map((item) => {
                    this.formData[item.fillControlName] = value[item.fromValName];
                });
            }
        },
        async onSubmit(values) {
            this.loading = true;
            // 过滤values，去掉无效信息和SourceData,字符转换数字
            for (const key in values) {
                if (key == "undefined" || key.includes("SourceData")) {
                    delete values[key];
                }
                if (this.template[0].find((x) => x.controlName == key) && this.template[0].find((x) => x.controlName == key).type == "number") {
                    values[key] = Number(values[key]);
                }
            }
            let context = values;
            let _this = this;
            console.log("====表单数据汇总", context, _this);
            // 验证表单数据
            let checkExpress = this.checkList.filter((x) => {
                try {
                    return eval(x.expr);
                } catch (error) {
                    console.log(error);
                }
            });
            console.log("====命中表单验证规则", checkExpress);
            let isCheckAllPass = true;
            if (checkExpress.length) {
                checkExpress.map((express) => {
                    express.rules.map((rule) => {
                        try {
                            if (eval(rule.expr)) {
                                // 通过验证
                            } else {
                                this.$itoast(rule.errorMsg);
                                isCheckAllPass = false;
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    });
                });
            }
            if (isCheckAllPass) {
                console.log("====筛选表单", values);

                this.filterFormValues = values;

                this.getList();
            } else {
                this.loading = false;
            }
        },

        hideFilter() {
            let filterBox = document.querySelector(`.topFilter`);
            filterBox.classList.remove("topFilterShow");
        },
        showFilter(e) {
            if (("showFilter", e.options.length == 0)) {
                let filterBox = document.querySelector(`.topFilter`);
                filterBox.classList.add("topFilterShow");
            } else {
                eval(e.expression);
                let list = document.querySelector(`#filterList${e.type}`);
                if (list.style.display == "block") {
                    list.style.display = "none";
                } else {
                    list.style.display = "block";
                }
            }
        },
        loadmoreScroll(event) {
            this.scrollTop = event.target.scrollTop;
        },
        loadData(e) {
            this.pageIndex = e.pageIndex;
            this.getList();
            // 拉取数据
            this.$refs.scrollWrapper.scrollListener();
        },
        async getList() {
            let params;
            let iDialog = Dialog;
            let _this = this;
            console.log(this.getListConfig.postOptions.beforeExpr);
            this.getListConfig.postOptions.beforeExpr && eval(this.getListConfig.postOptions.beforeExpr);
            let res = await this.$service.common(this.getListConfig.postOptions.url, params, { dontDialog: true });
            console.log("====commitEvent", res);
            this.getListConfig.postOptions.afterExpr && eval(this.getListConfig.postOptions.afterExpr);
        },
        touchmove(event) {
            event.preventDefault(); //阻止默认事件传播
            const _circle = event.targetTouches[0];
            const _create_event = document.getElementsByClassName("create_event")[0];
            const _clientWidth = document.documentElement.clientWidth;
            const _clientHeight = document.documentElement.clientHeight;
            if (_circle.pageX > 28 && _circle.pageX < _clientWidth - 28) {
                _create_event.style.left = -28 + _circle.pageX + "px";
            }
            if (_circle.pageY > 118 && _circle.pageY < _clientHeight - 84) {
                _create_event.style.top = -28 + _circle.pageY + "px";
            }
        },
    },
    data() {
        return {
            /**
             * 搜索参数
             */
            filterList: [],
            formData: {},
            template: null,
            checkList: null,
            getListConfig: null,

            /**
             * 列表配置，列表数据
             */
            listTemplateConfig: null,
            eventList: [],
            isFinished: false,
            pageIndex: 1,
            pageSize: 10,
            eventNumber: null,
            passEventInfo: this.$store.state.miceInfo,

            /**
             * 界面设置
             */
            fieldNames: {},
            loading: false,
            topHeight: 0,
            scrollTop: 0,
            bottomHeight: 0,
        };
    },
    async mounted() {
        this.$nextTick(() => {
            // this.topHeight = 80;
            // this.bottomHeight = this.topHeight;
        });

        // 读取列表配置
        const params = {
            collection: "cfg-ievent-eventList-template",
            filter: {
                tenantCode: "pfizerGroupEditorFromButton",
                tenantId: getCookie("tenant_id"),
            },
        };
        let res2 = await this.$service.QuerySettings(params);
        if (res2 && res2.success && res2.content.length) {
            console.log("====eventList模板配置", res2);
            this.listTemplateConfig = res2.content[0];
            this.listTemplateConfig.responseExpr;
            this.listTemplateConfig.responseExpr && eval(this.listTemplateConfig.responseExpr);
        }

        {
            // 读取内部筛选配置
            const params2 = {
                collection: "cfg-ievent-searchTemplate",
                filter: {
                    type: "editorGroupFrom",
                    tenantId: getCookie("tenant_id"),
                },
            };
            let res = await this.$service.QuerySettings(params2);
            if (res && res.success && res.content.length) {
                res.content[0].template.map((element) => {
                    element.map((item) => {
                        if (item.controlName) {
                            this.$set(this.formData, item.controlName, "");
                        }
                        if (item.type == "iCascaderRest") {
                            this.fieldNames[item.controlName] = {
                                text: item.txtName,
                                value: item.valName,
                                children: item.children,
                            };
                        }
                        return item;
                    });
                    return element;
                });

                this.getListConfig = res.content[0];

                let _this = this;
                console.log("====筛选表单", JSON.stringify(this.formData));
                console.log('EventCenterEditorGroup---',window.currentjoinitem);
                console.log(res.content[0].echoExpr);
                res.content[0].echoExpr && eval(res.content[0].echoExpr);
                console.log('formdata',_this.formData);
                this.stashTemplate = res.content[0].template;
                this.template = this.jsonCopy(this.stashTemplate);
                this.template[0] = [].concat(res.content[0].template[0]);
                this.checkList = res.content[0].checkList || [];

                console.log("====添加会议的表单template", this.stashTemplate);
                console.log("====表单验证规则", this.checkList);
            }
        }


    },
};
</script>
<style scoped="scoped" lang="less">
.groupheader{
    /deep/ .header-bg {
        opacity: 2!important;
    }
}
.form_box{
    padding: 0.27rem;
}
</style>
